.box {
  margin: 10px 24px !important;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 3px;
}

input {
  width: 100%;
}

.dropdown-item:hover,
.dropdown:hover {
  cursor: pointer;
}

.groupClass.customButtonsStyle>div>button:disabled {
  background-color: #7e7e7e !important;
  border-color: #7e7e7e !important;
  box-shadow: none !important;
  cursor: default !important;
  opacity: 1;
}

.bottom-buttons {
  position: fixed;
  bottom: 2%;
  right: 40px;
  display: none;
  z-index: 5;
}

.dropdownStringButton {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: #626262 !important;
  font-size: 20px !important;
  background-color: transparent !important;
  border: none !important;
}

.dropdownStringButton:focus {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.dropdown-item:hover {
  color: #563c91 !important;
  font-weight: bold !important;
}

.groupClass .margin-all {
  margin: 5px !important;
}

.groupClass .margin-all button {
  font-size: 12px !important;
}

.text-transform-none {
  text-transform: none !important;
}

.btn-shadow {
  /* margin-left: 10px !important; */
  box-shadow: rgba(86, 60, 145, 0.316) 0px 54px 55px,
    rgba(86, 60, 145, 0.316) 0px -12px 30px,
    rgba(86, 60, 145, 0.316) 0px 4px 6px, rgba(86, 60, 145, 0.316) 0px 12px 13px,
    rgba(86, 60, 145, 0.316) 0px -3px 5px !important;
}

.btn-shadow:hover {
  box-shadow: none !important;
}

.table-border {
  margin: 15px !important;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 5px 15px 0px 15px !important;
}

.table-mainBorder {
  margin: 15px !important;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 0px 15px 0px 15px !important;
}

.icon-commonstr {
  color: #4f8ec6be;
  font-size: 15px;
  margin: auto 0px;
  display: flex;
  justify-content: center;
  width: 34px;
}

.key-input {
  width: 100% !important;
  margin-right: 0px !important;
  margin-left: 10px !important;
}

.key {
  width: 100% !important;
  margin: auto 10px !important;
  font-weight: bold;
  color: #563c91c0;
  word-wrap: break-word !important;
}

.head-row {
  /* border-bottom: 1px ridge #e0e0e0 !important; */
  padding-bottom: 5px !important;
}

.edit-row {
  padding: 10px 0px;
  border-bottom: 1px solid #e0e0e0 !important;
}

.display-row {
  padding: 14px 0px;
  border-bottom: 1px solid #e0e0e0 !important;
}

.vertical-line {
  border-right: 1px solid #e0e0e0 !important;
}

.value {
  margin-top: auto !important;
  margin-right: 10px !important;
  margin-bottom: auto !important;
  margin-left: 10px !important;
  word-wrap: break-word !important;
  color: #616161;
}

.btnBorder {
  border: 1px solid #ced4da !important;
}

.leftRadius {
  border-left: 0px !important;
  border-radius: 0px 4px 4px 0px !important;
}

.react-select-container .react-select__control {
  border-color: #838383 !important;
  box-shadow: none !important;
}

.react-select-container .react-select__control:hover {
  border-color: #563c91 !important;
  box-shadow: none !important;
}

.customHeight {
  min-height: 30px;
}

.save-btn {
  border: none;
  background-color: transparent;
  border-right: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  color: #616161;
  padding-right: 15px !important;
  padding-left: 15px !important;
  border-radius: 0px 5px 5px 0px;
}

.save-btn-hover,
.form-save-btn-hover {
  border: 1px solid #86b7fe;
  box-shadow: none !important;
}

.save-btn:hover {
  color: #563c91;
}

.key-input:focus {
  box-shadow: none !important;
}

.val-input {
  width: 100%;
  border-right: none !important;
  box-shadow: none !important;
}

.input-focus {
  border: 1px solid #86b7fe !important;
}

.input-focus-val,
.val-input:focus {
  border-left: 1px solid #86b7fe !important;
  border-top: 1px solid #86b7fe !important;
  border-bottom: 1px solid #86b7fe !important;
  border-right: none !important;
}

.options-dots {
  margin: auto !important;
}

.options-dots:hover {
  cursor: pointer;
}

.editButtonSize {
  min-width: 60px !important;
}

.v-LineForNotSmallScreen {
  border-right: 1px solid #e0e0e0 !important;
}

.modalButtonSize {
  font-size: 16px !important;
  padding: 6px 12px 6px 12px !important;
}

@media only screen and (max-width: 575.98px) {
  .editButtonSize {
    min-width: 34px !important;
  }

  .modalButtonSize {
    font-size: 14px !important;
    padding: 6px 2px 6px 2px !important;
  }

  .v-LineForNotSmallScreen {
    border: none !important;
  }
}