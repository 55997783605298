body,
html {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Container {
  text-align: center;
  position: relative;
  min-height: 100%;
  width: 100%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: #cd5252;
}

.MainTitle {
  display: block;
  font-size: 30px !important;
  font-weight: bold;
  text-align: center;
  color: #cd5252;
}

.SecondTitle {
  display: block;
  font-size: 22px !important;
  font-weight: lighter;
  text-align: center;
  color: #cd5252;
}

.MainDescription {
  max-width: 600px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.errorPageLogo {
  font-size: 80px !important;
  line-height: 0.5 !important;
}

.text-transform-none {
  text-transform: none !important;
}