.message-error {
  font-size: 11px;
  font-family: roboto, "Noto Sans Myanmar UI", arial, sans-serif;
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  border-color: #f5c6cb;
  padding: 0.25rem 0.25rem;
  margin-bottom: 1rem;
}

.customHeader {
  padding: 15px 15px 0px 15px;
  font-family: "muli-semi-bold";
  font-size: 18px;
  color: #6a727a;
}

.detail-card {
  margin: 0px 14px;
  height: 100%;
  /* min-width: 240px; */
}

.modal.show {
  min-width: 280px !important;
}

.maxWidth-minContent {
  max-width: min-content !important;
}

.minWidth-minContent {
  min-width: min-content !important;
}

.minWidth-maxContent {
  min-width: max-content !important;
}

.whiteSpaceNoWrap {
  white-space: nowrap !important;
}

.detail-header {
  border-bottom: 2px solid #eeeeee;
  padding: 10px 20px 10px 20px;
}

.detail-heading {
  font-size: 21px !important;
}

.detail-status {
  margin-left: 10px !important;
}

.detail-description-box {
  padding: 20px 20px 0px 20px;
}

.detail-description-box2 {
  padding: 0px 20px 10px 20px;
}

.minmaxWidthCustom {
  min-width: 194px !important;
  max-width: 330px !important;
}

.detail-des {
  display: flex;
  margin-bottom: 4px;
}

.detail-heading-small {
  font-size: 15px;
  color: #000000 !important;
}

.project-form {
  margin: 10px;
}

#error-tag {
  display: none;
  color: red;
}

.detail_input {
  border: none;
  background-color: transparent;
  width: 200px;
}

.detail_input:focus {
  outline: none;
}

.form-select {
  color: #757575;
}



.multiselect-wrapper fieldset {
  /* background-color: green; */
}

.multiselect-wrapper fieldset.customClass {
  /* background-color: green; */
}


/* .form-select2>fieldset { */
/* border: none; */
/* top: 0px; */
/* border: 1px solid green; */
/* border-color: green; */
/* } */

.customClass {
  background-color: red;
  /* Other styles for .customClass */
}

.customClass .m-auto {
  /* Styles for the elements with both .customClass and .m-auto classes */
}



.formLoadingBtnText {
  text-transform: none;
  font-size: larger;
}

.text-transform-none {
  text-transform: none !important;
}

.display-input {
  width: 90px;
}

.centered {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}

.input-border:focus {
  box-shadow: none !important;
  border: 2px solid #007bff !important;
}