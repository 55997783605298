.tab_row:hover {
  cursor: pointer;
}

.text-transform-none {
  text-transform: none !important;
}

.platformPreviewPanel{
  width: 100%;
}

.platformPreviewPanel::before{
  content: "";
  position: absolute;
  top: 0;
  /* left: 0; */
  height: 100%;
  width: 1px;
  min-width: 1px;
  max-width: 1px;
  background-color: #dfdfdf;
}

.platformPreviewPanel >div:first-child{
  height: 100%;
  margin-left: 12px;
}

.platformPreviewPanel pre{
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  display: block;
  font-size: 13px;
  line-height: 1.42857143;
  margin: 0 0 10px;
  overflow: auto;
  padding: 9.5px;
  word-break: break-all;
  min-height: 180px;
}