.header {
  padding: 5px;
}

h2 {
  margin-bottom: 2rem !important;
  font-size: 1.74rem !important;
  letter-spacing: 0.05rem;
  color: #424242;
}

.version-dropdown:hover {
  cursor: pointer !important;
}

/* .add-btn {
  margin-left: auto;
} */

button {
  background-color: transparent;
  border: none;
}

.card-language i,
.version-dropdown i,
.add-btn i {
  font-size: 20px;
  color: #757575 !important;
  margin-left: 8px;
}

.add-btn p {
  margin-bottom: 0.2rem !important;
  font-size: 1.01rem !important;
  font-weight: bold;
  color: #424242;
}

.heading-language {
  color: #212121 !important;
  font-weight: 500;
  letter-spacing: 0.05rem;
  font-size: 1.2rem;
}

.detail,
.edit-form {
  margin: 5px 25px 15px 25px;
  color: #424242;
}

.c-outline-primary {
  width: 170px !important;
  font-size: 18px !important;
}

.rich-input:focus {
  border: 1px solid #563c91 !important;
  box-shadow: none !important;
}

.lan-type {
  color: #563c91 !important;
}

.text-transform-none {
  text-transform: none !important;
}