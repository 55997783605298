.del {
  color: red;
}

.add {
  width: 100%;
  justify-content: center;
  display: flex;
  color: #757575;
}

.provider-input {
  width: 395px;
}

.error-tag {
  color: red;
}

.error-tag:empty {
  display: none;
}

.text-transform-none {
  text-transform: none !important;
}