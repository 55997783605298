.custom-input  {
  /* display: flex; */
  /* align-items: center; */
  width: 100%;
  /* min-height: 40px; Set a suitable height */
  border: 1px solid #ced4da; /* Match Material UI input border color */
  border-radius: 4px; /* Match Material UI input border radius */
  padding: 4px 12px !important;
  font-size: 16px; /* Match Material UI input font size */
  line-height: 1.5; /* Match Material UI input line height */
  color: #495057; /* Match Material UI input text color */
  background-color: #fff; /* Match Material UI input background color */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; /* Match Material UI input transition */
  outline: none; /* Remove default outline */
  white-space: pre-wrap; /* Preserve line breaks and wrap text */
  /* min-height: 38px; */
  /* height: 38px; */
}

.custom-input.is-invalid {
  border: 1px solid #dc3545; /* Match Material UI input invalid state border color */
}

.custom-input.is-invalid:focus-within{
  z-index: 3;
  border: 1px solid #dc3545; /* Match Material UI input invalid state border color */
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25);
}

.custom-input.is-valid {
  border: 1px solid #28a745; /* Match Material UI input invalid state border color */
}

.custom-input.is-valid:focus-within{
  z-index: 3;
  border: 1px solid #28a745; /* Match Material UI input invalid state border color */
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.error {
  color: #dc3545; /* Match Material UI input error text color */
}

/* Additional Material UI-like styling for the form */
button {
  margin-top: 8px; /* Match Material UI input button margin */
}

/* ------------------------------------------------------ */

.custom-input .mentions{
  margin: 0px;
}

.custom-input .mentions__input{
  padding: 0px !important;
  color: black;
  mix-blend-mode: difference;
  caret-color: black;
  filter: invert(1);
}

.custom-input .mentions__input,
.custom-input .mentions__input:hover,
.custom-input .mentions__input:focus,
.custom-input .mentions__input:focus-visible,
.custom-input .mentions__input:focus-within {
  border: none !important;
  outline: none !important;
  /* box-shadow: none !important; */
}