.styled-div {
  margin-right: 8px;
}

.styled-div:hover {
  cursor: pointer !important;
}

.text-transform-none {
  text-transform: none !important;
}