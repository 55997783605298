@mixin ele-font($name) {
  font-family: $name !important;
}

.background-transparent {
  background: transparent !important;
}

@for $i from 0 through 100 {
  .border-radius-#{$i} {
    border-radius: $i * 1px !important;
  }

  ._fs-#{$i} {
    font-size: $i * 1px !important;
    &.light-text {
      @include ele-font("muli-light");
    }
    &.regular-text {
      @include ele-font("muli-regular");
    }
    &.medium-text {
      @include ele-font("muli-medium");
    }
    &.demi-bold-text {
      @include ele-font("muli-semi-bold");
    }
    &.bold-text {
      @include ele-font("muli-bold");
    }
  }

  .width-#{$i} {
    width: $i * 1px !important;
  }

  .minWidth-#{$i} {
    min-width: $i * 10px !important;
  }

  .maxWidth-#{$i} {
    max-width: $i * 10px !important;
  }

  .pa-#{$i} {
    padding: $i * 1px !important;
  }

  ._pr-#{$i} {
    padding-right: $i * 1px !important;
  }

  ._pl-#{$i} {
    padding-left: $i * 1px !important;
  }

  ._pt-#{$i} {
    padding-top: $i * 1px !important;
  }

  ._pb-#{$i} {
    padding-bottom: $i * 1px !important;
  }

  .plr-#{$i} {
    padding-left: $i * 1px !important;
    padding-right: $i * 1px !important;
  }
  .prl-#{$i} {
    padding-top: $i * 1px !important;
    padding-bottom: $i * 1px !important;
  }
  .ptb-#{$i} {
    padding-top: $i * 1px !important;
    padding-bottom: $i * 1px !important;
  }
  .pbt-#{$i} {
    padding-top: $i * 1px !important;
    padding-bottom: $i * 1px !important;
  }

  .ma-#{$i} {
    margin: $i * 1px !important;
  }

  ._mr-#{$i} {
    margin-right: $i * 1px !important;
  }

  ._mt-#{$i} {
    margin-top: $i * 1px !important;
  }

  ._mb-#{$i} {
    margin-bottom: $i * 1px !important;
  }

  ._ml-#{$i} {
    margin-left: $i * 1px !important;
  }

  .mlr-#{$i} {
    margin-left: $i * 1px !important;
    margin-right: $i * 1px !important;
  }
  .mrl-#{$i} {
    margin-left: $i * 1px !important;
    margin-right: $i * 1px !important;
  }
  .mtb-#{$i} {
    margin-top: $i * 1px !important;
    margin-bottom: $i * 1px !important;
  }
  .mbt-#{$i} {
    margin-top: $i * 1px !important;
    margin-bottom: $i * 1px !important;
  }
}

.mlr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.pos-relative {
  position: relative;
}

.headline {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: normal;
}

.pull-right {
  float: right;
}

.border-none {
  border: 0 !important;
}

.vert-middle {
  vertical-align: middle;
}

.flex {
  display: flex;
  &.center {
    align-items: center;
  }
}

.flex-x {
  display: flex;
  flex-direction: row;
  &.align-center {
    align-items: center;
  }
  &.center {
    align-items: center;
    justify-content: center;
  }
}

.flex-y {
  display: flex;
  flex-direction: column;
  &.center {
    align-items: center;
    justify-content: center;
  }
}

.space-between {
  justify-content: space-between;
}

.space-evenly {
  justify-content: space-evenly;
}

.flex-0 {
  flex: 0;
}

.flex-1 {
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.full-height {
  height: 100vh;
}

.fill-width {
  width: 100% !important;
}

.fill-height {
  height: 100% !important;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hide {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.with-transition {
  transition: all 0.3s ease-in;
}

.hover-border {
  &:hover {
    text-decoration: underline;
  }
}

.align-base {
  align-items: baseline;
}
